import React from 'react';
import styled, { css } from 'styled-components';

import { vars } from '../lib/theme';

const Tag = ({ className, children, ...rest }) => {
  return (
    <Root className={className} {...rest}>
      {children}
    </Root>
  );
};

export default Tag;

// Styles

const Root = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  box-shadow: ${vars.shadows[0]};
  padding: 4px 6px 3.5px 6px;
  /* margin-right: 6px;
  margin-bottom: 6px; */
  line-height: 1;
  font-family: ${vars.fonts.headings};
  font-size: 0.7rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  transition: all 0.2s ease;
  color: ${vars.colors.white};
  ${(props) => {
    switch (props.theme) {
      case 'primary':
        return css`
          color: ${vars.colors.primary.darkest};
          background-color: ${vars.colors.primary.lighter};
        `;
      case 'secondary':
        return css`
          color: ${vars.colors.secondary.darkest};
          background-color: ${vars.colors.secondary.lighter};
        `;
      case 'warning':
        return css`
          color: ${vars.colors.warning.darkest};
          background-color: ${vars.colors.warning.lighter};
        `;
      case 'success':
        return css`
          color: ${vars.colors.success.darkest};
          background-color: ${vars.colors.success.lighter};
        `;

      case 'guitar-technique':
        return css`
          background-color: ${vars.colors.aqua.darker};
        `;
      case 'recording-and-production':
        return css`
          background-color: ${vars.colors.primary.dark};
        `;
      case 'music-theory':
        return css`
          background-color: ${vars.colors.tertiary.darker};
        `;
      case 'gear-tips':
        return css`
          background-color: ${vars.colors.blue.darker};
        `;
      default:
        return css`
          color: ${vars.colors.grey.darkest};
          background-color: ${vars.colors.grey.lighter};
        `;
    }
  }};
`;
